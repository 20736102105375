.orange-row {
  background-color: orange;
}

.green-row {
  background-color: lightgreen;
}

.lightyellow-row {
  background-color: lightyellow;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.config-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pr-02rem {
  padding-right: 0.2rem;
}

.pr-05rem {
  padding-right: 0.5rem;
}

.pl-05rem {
  padding-left: 0.5rem;
}

.pt-05rem {
  padding-top: 0.5rem;
}

.mr-05rem {
  margin-right: 0.5rem;
}

.fs-075rem {
  font-size: 0.75rem;
}

.index-margin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
